@tailwind base;
@tailwind components;
@tailwind utilities;
@import "leaflet/dist/leaflet.css";


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 38.84, 84.82%, 43.92%;
    /* --primary: 24.6 95% 53.1%; */
    --primary-hover: 38.82, 100%, 50%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.3rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --theme-black: 0, 0%, 25.88%;
    --theme-black-foreground: 0, 0%, 38.82%
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 20.5 90.2% 48.2%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

:where([data-sonner-toast]) :where([data-close-button]) {
  position: relative;
  right: 0 !important;
  top: 0 !important;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #333 !important;
  color: #fff !important;
}

.text-security-disc {
  -webkit-text-security: disc;
}

.text-security-none {
  -webkit-text-security: none;
}

.carousel-container {
  scroll-snap-type: x mandatory;
}

.carousel-item {
  scroll-snap-align: start;
}

/* For Edge */
::-ms-input-placeholder {
  color: lightgray !important;
}

/* For Chrome, Safari, Opera */
::-webkit-input-placeholder {
  color: lightgray !important;
}


.remove-obstacles {
  top: 10px;
  left: 5px;
  background-color: #85a3ff;
  border-radius: 50%;
}

/* Information panel */
.info-panel {
  position: absolute;
  right: 0;
  bottom: 50px;
  font-size: 14px;
  color: white;
  background-color: #85a3ff;
}

.info {
  margin: 5px 0;
}

.obstacles-info {
  background-color: #ff75b2;
}

.panel-info {
  background-color: #5d9eff;
}

/* Action button */
.action-button {
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #85a3ff;
  color: white;
  z-index: 10;
}

.map, .three-canvas {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}

.progress-bar {
  height: 4px;
  background-color: rgba(206, 92, 5, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(224, 59, 4);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}